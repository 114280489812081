<template>
  <ui-component-modal
    modalTitle="Edit legal &amp; bank"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr>
            <td>C.o.C. name</td>
            <td>
              <input type="text" v-model="mLocation.CoCName" class="input" />
            </td>
          </tr>
          <tr>
            <td>C.o.C. number</td>
            <td>
              <input type="text" v-model="mLocation.CoCNumber" class="input" />
            </td>
          </tr>
          <tr>
            <td>VAT number</td>
            <td>
              <input type="text" v-model="mLocation.VATNumber" class="input" />
            </td>
          </tr>
          <tr>
            <td>Bank account number</td>
            <td>
              <input
                type="text"
                v-model="mLocation.BankAccountNumber"
                class="input"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mLocation: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.mLocation = JSON.parse(JSON.stringify(this.location))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    saveLocation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .saveLocationData(self.mLocation)
          .then((response) => {
            if (response.status === 200) {
              self.setLocation(self.mLocation)

              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
              }, 1000)
            }
          })
          .catch((error) => {
            ////console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
